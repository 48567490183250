<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <ApexChart
                    v-if="hasGsmSignal"
                    height="200"
                    :options="chartOptionsGsmSignal"
                    :series="seriesGsmSignal"
                />

                <template v-else>
                    <ApexChart
                        height="200"
                        :options="chartOptionsRssi"
                        :series="seriesRssi"
                    />

                    <ApexChart
                        height="200"
                        :options="chartOptionsSf"
                        :series="seriesSf"
                    />

                    <ApexChart
                        height="200"
                        :options="chartOptionsSnr"
                        :series="seriesSnr"
                    />
                </template>

                <ApexChart
                    height="200"
                    :options="chartOptionsCounter"
                    :series="seriesCounter"
                />
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const baseChartOptions = {
    chart: {
        group: 'network',
        animations: {
            enabled: false,
        },
        zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
        },
    },
    stroke: {
        curve: 'straight',
    },
    markers: {
        size: 3,
    },
    dataLabels: {
        enabled: false,
    },
    colors: [process.env.VUE_APP_COLOR_PRIMARY],
    xaxis: {
        type: 'datetime',
        labels: {
            datetimeUTC: false,
        },
    },
    tooltip: {
        shared: false,
        x: {
            format: 'dd.MM.yy HH:mm:ss',
        },
    },
}

export default {
    name: 'NetworkChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            seriesCounter: [
                {
                    name: this.$t('counter'),
                    data: [],
                },
            ],
            seriesGsmSignal: [
                {
                    name: this.$t('gsmSignal'),
                    data: [],
                },
            ],
            seriesRssi: [
                {
                    name: this.$t('rssi'),
                    data: [],
                },
            ],
            seriesSf: [
                {
                    name: this.$t('sf'),
                    data: [],
                },
            ],
            seriesSnr: [
                {
                    name: this.$t('snr'),
                    data: [],
                },
            ],
            chartOptionsCounter: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'counter' },
                yaxis: [
                    {
                        min: 0,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('counter'),
                        },
                    },
                ],
            },
            chartOptionsGsmSignal: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'gsmSignal' },
                yaxis: [
                    {
                        min: 0,
                        max: 5,
                        title: {
                            text: this.$t('gsmSignal'),
                        },
                    },
                ],
            },
            chartOptionsRssi: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'rssi' },
                yaxis: [
                    {
                        max: -20,
                        min: -130,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('rssi'),
                        },
                    },
                ],
            },
            chartOptionsSf: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'sf' },
                yaxis: [
                    {
                        min: 7,
                        max: 12,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('sf'),
                        },
                    },
                ],
            },
            chartOptionsSnr: {
                ...baseChartOptions,
                chart: { ...baseChartOptions.chart, id: 'snr' },
                colors: [process.env.VUE_APP_COLOR_ACCENT],
                yaxis: [
                    {
                        max: 10,
                        min: -20,
                        forceNiceScale: true,
                        title: {
                            text: this.$t('snr'),
                        },
                    },
                ],
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            hasGsmSignal: false,
            isLoading: false,
        }
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        async load() {
            this.isLoading = true
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,network_data,counter' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            this.hasGsmSignal = results.some(
                item => typeof item.network_data?.gsm_signal === 'number'
            )

            this.seriesCounter[0].data = results.map(item => [
                item.timestamp,
                item.counter,
            ])
            this.seriesGsmSignal[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.gsm_signal,
            ])
            this.seriesRssi[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.rssi,
            ])
            this.seriesSf[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.sf,
            ])
            this.seriesSnr[0].data = results.map(item => [
                item.timestamp,
                item.network_data?.snr,
            ])
        },
    },
}
</script>

<i18n>
{
    "en": {
        "counter": "Counter",
        "gsmSignal": "GSM signal",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "de": {
        "counter": "Counter",
        "gsmSignal": "GSM-Signal",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "fr": {
        "counter": "Counter",
        "gsmSignal": "Signal GSM",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    },
    "it": {
        "counter": "Counter",
        "gsmSignal": "Segnale GSM",
        "rssi": "RSSI",
        "sf": "SF",
        "snr": "SNR"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    padding: 1rem 1rem 1rem 0;
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
}

.datepicker {
    width: 20%;
    min-width: 350px;
    border-right: $style-border;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
